<template>
  <header
    class="question-header"
    :class="{'image-question': data.fields.questionImage, 'place-bottom': noTitle}"
    v-if="canRender"
  >
    <div v-if="!data.fields.questionImage">
      <h2
        @click="playAudio()"
        v-if="!noTitle"
      >{{data.fields.questionTitle || data.fields.title || data.fields.name}}</h2>
    </div>

    <div
      class="img"
      v-if="data.fields.questionImage && !noTitle"
      :title="data.fields.questionImage.fields.title"
      :style="{'backgroundImage': `url(${data.fields.questionImage.fields.file.url})`}"
    ></div>

    <div v-if="data.fields.audioQuestion" @click="playAudio()">
      <div class="play-icon slideEntry" :class="{'playing': playing, 'place-bottom': noTitle}">
        <Icons class="icon" icon="play-outline" size="45" color="#f56c6c"></Icons>
      </div>
      <audio ref="player" autoplay="true">
        <source
          :src="data.fields.audioQuestion.fields.file.url"
          :type="data.fields.audioQuestion.fields.file.contentType"
        />
      </audio>
    </div>

    <div v-if="data.fields.questionImage && !noQuestionTitle">
      <h2
        @click="playAudio()"
        v-if="!noTitle"
      >{{data.fields.questionTitle || data.fields.title}}</h2>
    </div>
  </header>
</template>

<script>
export default {
  name: 'QuestionHeader',

  props: [ 'data', 'noQuestionTitle', 'noTitle' ],

  components: {
    Icons: () => import('Components/base/Icons')
  },

  data () {
    return {
      canRender: false,
      playing: false
    }
  },

  methods: {
    skip () {
      this.skipped = true
      this.$emit('skip')
    },
    submit () {
      this.$emit('submit')
    },
    playAudio () {
      if (this.$refs.player) {
        if (!this.$refs.player.paused) {
          this.isPlaying(false)
          this.$refs.player.pause()
        } else {
          this.$refs.player.play()
          this.isPlaying(true)
        }
      }
    },
    isPlaying (event) {
      this.playing = event.type === 'playing'
    }
  },
  mounted () {
    setTimeout(() => {
      this.canRender = true
    }, 100)
    if (this.$refs.player) {
      this.$refs.player.addEventListener('playing', this.isPlaying)
      this.$refs.player.addEventListener('ended', this.isPlaying)
    }
  }

}
</script>

<style scoped lang="scss">
.help-text {
  color: $gray-medium;
  padding: 0 10px;
  @include breakpoint($micro) {
    font-size: 1em;
  }
  @include breakpoint($desktop) {
    font-size: 0.9em;
  }
}

.question-header {
  margin-bottom: 2em;
  h3 {
    margin-top: 0;
    margin-bottom: 0.2em;
  }
  @include breakpoint($desktop) {
    padding: 0;
    padding-top: 40px;
  }
  @include breakpoint($air) {
    padding: 20px;
  }
}
.hide {
  display: none;
}
.play-icon {
  transition: all 0.2s ease-in-out;
  height: 42px;
  border-radius: 5px;
  margin-left: 0.5em;
  background: white;
  &:hover {
    cursor: pointer;
  }
  &.playing {
    opacity: 0.2;
  }
  @include breakpoint($tablet) {
    margin-top: 0.5em;
    margin-left: 0;
  }
}

.question-header {
  max-height: 20vh;
  overflow: hidden;
  padding: 10px 20px;
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @include breakpoint($micro) {
    align-items: flex-start;
    max-height: 22vh;
    margin-bottom: 0;
    padding: 4px 6px;
  }
  @include breakpoint($tablet) {
    padding: 4%;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }
  @include breakpoint($desktop) {
    padding: 4%;
    max-width: calc(130vw * (9/16));
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }

  h2 {
    margin-bottom: 0;
    font-size: 22px;
    padding: 0;
    @include breakpoint($micro) {
      font-size: 15px;
      line-height: 1;
    }
    @include breakpoint($desktop) {
      font-size: 20px;
    }
    @include breakpointHeight($square) {
      font-size: 18px;
    }
  }

  &.image-question {
    max-height: fit-content;
    padding: 0;
    margin: 10px auto 40px;
    position: relative;
    align-items: flex-start;
    max-height: calc(18px + calc(100vw - 30px) * 3 / 4);

    @include breakpointHeight($square) {
      overflow: visible;
      transform: scale(0.752);
      margin-bottom: 20px;
    }
    @include breakpoint($micro) {
      overflow: visible;
      margin-top: 4px;
      margin-bottom: 0;
    }
    @include breakpoint($tablet) {
      max-height: calc(18px + calc(100vw - 30px) * 3 / 4);
      margin-top: 0;
    }
    @include breakpoint($desktop) {
      max-height: 300px;
    }
    @include breakpoint($fullhd) {
      max-height: 350px;
    }
    .img {
      height: 0;
      padding-bottom: calc(calc(100vw - 20px) * 3 / 4);
      width: calc(100vw - 20px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border: 5px solid $gray-light;

      @include breakpoint($tablet) {
        padding: 0;
        width: 360px;
        height: 270px;
      }
      @include breakpoint($desktop) {
        width: 370px;
        height: 278px;
        padding: 0;
      }
      @include breakpoint($fullhd) {
        width: 460px;
        height: 345px;
        padding: 0;
      }
    }
    .play-icon {
      position: absolute;
      width: 42px;
      border-radius: 100px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 7px;

      .icon {
        transform: translate(-1px, -3px);
      }
    }
  }

  &.contain-image {
    &.image-question {
      .img {
        background-size: cover;
      }
    }
  }

  &.align-bottom {
    max-height: 25vh;
    height: 25vh;
    @include breakpoint($micro) {
      align-items: flex-end;
    }
  }
}

.slideEntry {
  animation: shakeItMore 3.22s ease-in-out infinite forwards;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-delay: 1.2s;
}

@keyframes shakeItMore {
  0% { transform: rotate(0deg) scale(0.95);}
  5% { transform: rotate(-1deg) scale(0.98); }
  10% { transform: rotate(0deg) scale(0.95); }
  20% { transform: rotate(-0deg) scale(1.1); }
  30% { transform: rotate(0deg) scale(0.93); }
  35% { transform: rotate(1deg) scale(0.96); }
  40% { transform: rotate(-0deg) scale(0.95); }
  100% { transform: rotate(-0deg) scale(0.95); }
}

.question-header {
  &.place-bottom {
    padding: 0;
    min-height: 0;
    max-height: 0;
    height: 0;
  }
}
.play-icon {
  &.place-bottom {
    position: absolute;
    bottom: 1em;
    right: 0;
    left: 0;
  }
}
</style>
